import axios from 'axios'
import {
  Loading,
  Message
} from 'element-ui'
import router from '../router/index.js'
import {
  getCookie,
  delCookie
} from '@/utils/cookie.js'
import {
  tokenName,
  baseUrl
} from "../config/project"
let loading

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中....',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {
  if (loading) {
    loading.close()
  }
}
//超时2分钟
// console.log(axios.defaults)
axios.defaults.timeout = 1000 * 60 * 2;
axios.defaults.baseURL = baseUrl;
// 请求拦截
axios.interceptors.request.use(
  (confing) => {

    let token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : "";
    let lang = "zh"
    if(localStorage.getItem("localLangFlag")){
      lang = localStorage.getItem("localLangFlag")
    }
    confing.headers["Accept-Language"] = lang // 设置语言
    //设置请求头
    if (token) {
      // config.headers["x-auth-token"] = token
      confing.headers.Authorization = "Bearer " + token
    } else {
      router.replace('/login')
    }
    return confing
  },
  (error) => {
    Message.error('服务异常！')
    endLoading()
    return Promise.reject(error)
  }
)

//响应拦截
axios.interceptors.response.use(
  (response) => {
    endLoading()
    //当返回信息为未登录或者登录失效的时候重定向为登录页面
    // if(response.data.code == 'W_100004' || response.data.message == '用户未登录或登录超时，请登录！'){
    //   router.push({
    //     path:"/",
    //     querry:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
    //   })
    // }
    // 获取状态码
    const {
      code
    } = response.data
    if (code === 422 || code === 401 || code === 403) {
      Message.error('请重新登录')
      //清楚token
      delCookie("token");
      delCookie("userMessObj");
      //跳转到登录页面
      router.replace('/login')
    } else if(code !== 200){
      Message.error(response.data.message)
    }
    if (response.data.code != 200 && response.code === 1) {
      Message.error(response.data.message)
      return false
    } else {
      return response.data
    }

  },
  (error) => {
    const { code } = error.response.data
    if (code === 401 || code === 403) {
      Message.error('请重新登录')
      //清楚token
      delCookie("token");
      delCookie("userMessObj");
      //跳转到登录页面
      router.push('/login')
    } else {
      Message.error(error.response.data.message ? error.response.data.message : '网络错误');
    }
    endLoading()


    return Promise.reject(error)
  }
)
export default axios