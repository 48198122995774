import request from "../utils/http"
// import axios from "./httpp"
import {
  baseUrl
} from "@/config/project"

// 接收机管理-接收机信息 / 接收机状态-位置信息 / 接收机配置-天线高设置-获取天线高设置信息
export function receiverInfo(param) {
  return request({
    url:"/v1/gnss/device/detail",
    method:"get",
    params:param,
  })
}
// 位置信息
// export function locationInfo(param) {
//   return request({
//     url:"/api/v1/receiver/location_info",
//     method:"get",
//     params:param,
//   })
// }
// 接收机信息-卫星信息
export function starsInfo(param) {
  return request({
    url:"/v1/gnss/device/satellite",
    method:"get",
    params:param,
  })
}
// 接收机配置-IO配置
export function receiverNetConfig(param) {
  return request({
    url:"/v1/gnss/device/io_config",
    method:"post",
    data:param,
  })
}
// 接收机配置-卫星设置
export function receiverProConfig(param) {
  return request({
    url:"/v1/gnss/device/satellite",
    method:"post",
    data:param,
  })
}
// 接收机配置-获取卫星设置
export function satelliteInfo(param) {
  return request({
    url:"/v1/gnss/device/satellite/info",
    method:"get",
    params:param,
  })
}
// 接收机远程诊断
export function receiverDiagnosis(param) {
  return request({
    url:"/v1/gnss/device/diagnosis",
    method:"post",
    data:param,
  })
}
// 接收机设备列表
export function receiverList(param) {
  return request({
    url:"/v1/gnss/device/list",
    method:"get",
    params:param,
  })
}
// 接收机管理-固件更新-上传文件
export function receiverFileUploads(param) {
  return request({
    // url:"http://47.104.131.194:23735/api/v2/farm/receiverFileUploads",
    url:"/v1/gnss/device/upload",
    method:"post",
    data:param,
  })
}
// 接收机管理-固件更新-固件升级
export function receiverUpdate(param) {
  return request({
    // url:"/api/v1/receiver/firmware_upgrade",
    url:"/v1/gnss/device/upgrade",
    method:"post",
    data:param,
  })
}
// 设备管理-分配设备
export function userInfoList(param) {
  return request({
    url:"/v1/gnss/user/all",
    method:"get",
    params:param,
  })
}
// 获取验证码
export function getCaptcha(param) {
  return request({
    url:"/v1/gnss/captcha",
    method:"get",
    params:param,
  })
}
// 登录
export function login(param) {
  return request({
    url:"/v1/gnss/login",
    method:"post",
    data:param,
  })
}
// 添加用户
export function userAdd(param) {
  return request({
    url:"/v1/gnss/user/add",
    method:"post",
    data:param,
  })
}
// 用户管理-添加用户-校验账号是否存在
export function checkAccount(param) {
  return request({
    url:"/v1/gnss/user/account",
    method:"get",
    params:param,
  })
}
// 用户管理-用户列表
export function userList(param) {
  return request({
    url:"/v1/gnss/user/list",
    method:"get",
    params:param,
  })
}
// 用户管理-经销商列表
export function checkAgencyList(param) {
  return request({
    url:"/v1/gnss/agent/list",
    method:"get",
    params:param,
  })
}
// 用户管理-密码重置
export function resetPassword(param) {
  return request({
    url:"/v1/gnss/user/reset",
    method:"post",
    data:param,
  })
}
// 用户管理-删除用户
export function deleteUser(param) {
  return request({
    url:"/v1/gnss/user/delete",
    method:"post",
    data:param,
  })
}
// 退出登录
export function loginOut(param) {
  return request({
    url:"/v1/gnss/logout",  
    method:"get",
    params:param,
  })
}
// 设备管理-校验SN是否存在
export function checkSN(param) {
  return request({
    url:"/v1/gnss/device/sn",
    method:"get",
    params:param,
  })
}
// 设备管理-批量添加设备
export function receiverAddList(param) {
  return request({
    url:"/v1/gnss/device/batch",
    method:"post",
    data:param,
  })
}
// 设备管理-批量删除设备
export function deleteSN(param) {
  return request({
    url:"/v1/gnss/device/device",
    method:"post",
    data:param,
  })
}
// 设备管理-添加设备
export function addReceiver(param) {
  return request({
    url:"/v1/gnss/device/add",
    method:"post",
    data:param,
  })
}
// 用户管理-分配设备
export function receiverAssign(param) {
  return request({
    url:"/v1/gnss/device/assign",
    method:"post",
    data:param,
  })
}
// 卫星设置-I/O配置
export function IOList(param) {
  return request({
    url:"/v1/gnss/device/io_config",
    method:"get",
    params:param,
  })
}
// I/O配置-开始 / 停止 某条配置
export function startOrStop(param) {
  return request({
    url:"/v1/gnss/device/io_config/switch",
    method:"post",
    data:param,
  })
}
// 设备配置-天线高设置
export function antennaHigh(param) {
  return request({
    url:"/v1/gnss/device/antenna",
    method:"post",
    data:param,
  })
}
// 设备配置-天线高原始数据
// export function getConfigInfo(param) {
//   return request({
//     url:"/api/v1/receiver/receiver_info",
//     method:"get",
//     params:param,
//   })
// }
// 个人中心-用户修改密码
export function editPwd(param) {
  return request({
    url:"/v1/gnss/user/modify",
    method:"post",
    data:param,
  })
}
// 用户管理-编辑信息
export function editInfo(param) {
  return request({
    url:"/v1/gnss/user/update",
    method:"post",
    data:param,
  })
}
// 个人中心/用户管理-编辑信息
export function editUserInfo(param) {
  return request({
    url:"/v1/gnss/user/myself",
    method:"post",
    data:param,
  })
}
// 个人中心-获取用户详情
export function getUserInfo(param) {
  return request({
    url:"/v1/gnss/user/detail",
    method:"get",
    params:param,
  })
}
// 个人中心/用户管理-获取地区
export function getLocation(param) {
  return request({
    url:"/v1/gnss/region/get",
    method:"get",
    params:param,
  })
}
// 位置地图-查询设备下拉SN号
export function getSN(param) {
  return request({
    url:"/v1/gnss/device/sn",
    method:"post",
    data:param,
  })
}
// 工作模式-保存配置
export function configWorkMode(param) {
  return request({
    url:"/v1/gnss/device/work_mode",
    method:"post",
    data:param,
  })
}
// 工作模式-原始数据查询
export function getWorkInfo(param) {
  return request({
    url:"/v1/gnss/device/work_mode",
    method:"get",
    params:param,
  })
}
// 工作模式-获取接入点
export function getAccessPoint(param) {
  return request({
    url:"/v1/gnss/device/point",
    method:"get",
    params:param,
  })
}
// 设备管理-活动
export function getActivity(param) {
  return request({
    url:"/v1/gnss/device/activity",
    method:"get",
    params:param,
  })
}
// 接收机配置-NMEA消息信息集
export function getNMEA(param) {
  return request({
    url:"/v1/gnss/device/NMEA",
    method:"get",
    params:param,
  })
}
// 接收机配置-配置NMEA消息
export function setNMEA(param) {
  return request({
    url:"/v1/gnss/device/NMEA",
    method:"post",
    data:param,
  })
}
// 指令配置-开始配置（密钥检验）
export function getVerify(param) {
  return request({
    url:"/receiver/getVerify",
    method:"post",
    data:param,
  })
}
// 指令配置-停止配置
export function closeCommand(param) {
  return request({
    url:"/receiver/closeCommand",
    method:"post",
    data:param,
  })
}
// 指令配置-指令发送
export function sendCommand(param) {
  // if(param.command.slice(-4) !== '\r\n'){
  //   param.command = param.command + '\r\n'
  // }
  return request({
    url:"/receiver/sendCommand",
    method:"post",
    data:param,
  })
}